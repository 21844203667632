import {initializeApp, getApps} from 'firebase/app'
import {getAuth} from 'firebase/auth'
import {connectFirestoreEmulator, getFirestore} from 'firebase/firestore'
// import {getDatabase} from 'firebase/database'
import {env} from './constants'

const config = {
  apiKey: 'AIzaSyCR1oteCf0oGnq0HXymDmcn0jOf7IXUmuo',
  authDomain: 'cssbattleapp.firebaseapp.com',
  databaseURL: 'https://cssbattleapp.firebaseio.com',
  projectId: 'cssbattleapp',
  storageBucket: 'cssbattleapp.appspot.com',
  messagingSenderId: '924387938820',
}

const configStaging = {
  apiKey: 'AIzaSyCm7WWpsZ1EycmQR-NBvTMToS1lZ9gfGpw',
  authDomain: 'cssbattle-756.firebaseapp.com',
  databaseURL: 'https://cssbattle-756.firebaseio.com',
  projectId: 'cssbattle-756',
  storageBucket: 'cssbattle-756.appspot.com',
  messagingSenderId: '423644073145',
  appId: '1:423644073145:web:71a6e7778358269017df68',
}

const apps = getApps()
export const firebaseApp = apps.length
  ? apps[0]
  : initializeApp(env === 'development' ? configStaging : config)

export const PROJECT_ID =
  env === 'development' ? 'cssbattle-756' : 'cssbattleapp'

export const auth = getAuth(firebaseApp)
export const db = getFirestore(firebaseApp)
if (!db._settingsFrozen && env === 'development') {
  // connectFirestoreEmulator(db, '127.0.0.1', 8082)
}
// export const rtdb = getDatabase(firebaseApp)
