import {forwardRef} from 'react'

const appearanceStyles = {
  normal: {
    color: 'var(--clr-text)',
  },
  primary: {
    color: 'var(--clr-heading)',
  },
  secondary: {
    color: 'var(--clr-text-light)',
  },
  tertiary: {
    color: 'var(--clr-text-lightest-final)',
  },
  brand: {
    color: 'var(--clr-brand)',
  },
}

export const Text = forwardRef(
  (
    {
      size = 0,
      weight = 'normal',
      tag,
      style = 'normal',
      appearance = 'normal',
      mono = true,
      letterSpacing = 0,
      lineHeight = 1.4,
      transform = 'none',
      align = 'left',
      maxChars = null,
      classes = '',
      children,
    },
    ref
  ) => {
    const Tag = tag || 'span'

    letterSpacing = !mono ? letterSpacing || '0.3px' : letterSpacing

    const styles = {
      letterSpacing: letterSpacing,
      fontSize: `var(--font-size-${size})`,
      fontFamily: mono ? 'IBM Plex Mono' : 'ClashGrotesk',
      fontWeight: weight,
      textAlign: align,
      lineHeight: lineHeight,
      fontStyle: style === 'italic' ? 'italic' : 'normal',
      maxWidth: maxChars ? `${maxChars}ch` : undefined,
      textTransform: transform,
      ...appearanceStyles[appearance],
    }

    return (
      <Tag style={styles} className={classes} ref={ref}>
        {children}
      </Tag>
    )
  }
)
