import React, {useEffect} from 'react'
import styles from './CSSBattleSidebar.module.css'
import Link from 'next/link'
import {Icon} from 'src/Icon'
import {useRouter} from 'next/router'
import {useUser} from 'src/hooks/useUser'
import {ProBadge} from '@components/ProBadge'
import {HStack, VStack} from './Stack'
import {useWhenMounted} from 'src/hooks/useWhenMounted'
import {Text} from './Text'
import {
  BattleStatuses,
  battles,
  getBattleStatus,
} from '@cssbattle/shared/battles'
import {GlowButton} from './GlowButton'
import {CircularProgressBar} from './CircularProgressBar'
import {Dropdown} from 'src/Dropdown'
import {useState} from 'react'
import {Separator} from 'src/Common'
import * as AlertDialog from '@radix-ui/react-alert-dialog'
import {Button} from 'src/Button'
import {Panel} from './Panel'
import {getLastDailyTargetScoreForPlayer} from 'src/util/db'
import {useLocalStorage} from 'src/hooks/useLocalStorage.js'
import {sendEvent} from 'src/analytics'

const SidebarSocialIcon = ({icon, link}) => {
  return (
    <a href={link} className={styles.sidebarSocialIcon}>
      <Icon name={icon} size="18" marginRight="0" />{' '}
    </a>
  )
}

const SidebarPageItem = ({icon, title, link, tag, matchRegex}) => {
  const currentPath = useRouter().asPath
  const isActive = currentPath == link || matchRegex?.test(currentPath)

  return (
    <Link href={link}>
      <a
        className={`${styles.sidebarPageItem}
      ${isActive ? styles.sidebarPageItemActive : ''}`}
      >
        <Icon name={icon} size="18" marginRight="0" />
        <span className={styles.sidebarPageItemText}>{title}</span>

        {tag ? <span className={styles.sidebarPageItemTag}>{tag}</span> : null}
      </a>
    </Link>
  )
}

const OnboardingList = () => {
  const user = useUser()
  const [states, setStates] = React.useState({})
  const [doneCount, setDoneCount] = React.useState(0)
  useEffect(() => {
    if (user) {
      Promise.all([getLastDailyTargetScoreForPlayer(user.uid)]).then(
        ([dailyTargetScore]) => {
          const newStates = {
            account: true,
            username: !!user.username,
            daily: !!dailyTargetScore,
            streakReminder: !!user.settings?.streakMailNotifications,
          }

          setStates(newStates)

          setDoneCount(Object.values(newStates).filter((v) => v).length)
        }
      )
    }
  }, [user])

  return (
    <Dropdown
      dropdownContainerClasses="dropdown-container--onboarding"
      dropdownBtnClasses="dropdown-btn--minimal dropdown-btn--no-padding"
      isFixed={true}
      ignoreScrollTop={true}
      direction="right"
      onOpen={() => {
        sendEvent({
          name: 'onboardingOpen',
        })
      }}
      dropdownBtnContent={
        <>
          <CircularProgressBar
            thickness={2.5}
            percent={(doneCount / 4) * 100}
          />
          <span className={styles.sidebarPageItemText}>{doneCount}/4 done</span>
        </>
      }
    >
      <div style={{padding: '0.75rem 0'}}>
        <VStack gap="1rem" align="stretch">
          <OnboardingChecklistItem
            text="Create an account"
            href="/login"
            isFinished={states.account}
          />
          <OnboardingChecklistItem
            text="Play a daily target"
            href="/play/latest"
            isFinished={states.daily}
          />
          <OnboardingChecklistItem
            text="Reserve your public username"
            href="/settings?tab=profile"
            isFinished={states.username}
          />
          <OnboardingChecklistItem
            text="Set up streak reminders"
            href="/settings?tab=notifications"
            isFinished={states.streakReminder}
          />

          <Separator />

          <Text mono={false} weight={500} appearance="tertiary" size={1}>
            Explore more
          </Text>
          <a
            className="link--no-underline"
            href="https://discord.gg/GPC8MjbaHu"
          >
            <HStack gap="0.5rem">
              Join our discord
              <Icon name="link" size="12" color="var(--clr-text-light)" />
            </HStack>
          </a>
          <OnboardingItem href="/tips">
            See tips & tricks to score better
          </OnboardingItem>
          <OnboardingItem href="/learn">
            Check out our CSS courses
          </OnboardingItem>
          <OnboardingItem href="/faqs">Read FAQs</OnboardingItem>

          <Separator />

          <AlertDialog.Trigger asChild>
            <button
              href="#"
              className="link--no-underline"
              style={{
                background: 'none',
                textAlign: 'left',
                padding: 0,
                border: 0,
                fontWeight: 'inherit',
              }}
              onClick={() => {
                console.log(999343)
                sendEvent({
                  name: 'onboardingSkipClick',
                })
              }}
            >
              Skip onboarding
            </button>
          </AlertDialog.Trigger>
        </VStack>
      </div>
    </Dropdown>
  )
}

const OnboardingItem = ({children, href = ''}) => {
  return (
    <Link href={href}>
      <a
        className="link--no-underline"
        onClick={() => {
          sendEvent({
            name: 'onboardingItemClick',
            label: href,
          })
        }}
      >
        {children}
      </a>
    </Link>
  )
}
const OnboardingChecklistItem = ({text, href = '', isFinished = false}) => {
  return (
    <HStack>
      <div className={styles.onboardingCheckmark} data-checked={isFinished}>
        {isFinished ? (
          <Icon name="tick" color="white" size="14" marginRight="0" />
        ) : null}
      </div>
      <Link href={href}>
        <a
          onClick={() => {
            sendEvent({
              name: 'onboardingItemClick',
              label: href,
            })
          }}
          style={{
            textDecoration: isFinished ? 'line-through' : 'none',
            color: isFinished
              ? 'var(--clr-text-lightest-final)'
              : 'var(--clr-link)',
          }}
        >
          {text}
        </a>
      </Link>
    </HStack>
  )
}

const CSSBattleSidebar = ({collapsible = false, visible = false}) => {
  const year = new Date().getFullYear()
  const isMounted = useWhenMounted()
  const [showIntro, setShowIntro] = useLocalStorage('showOnboarding', true)

  let isBattleRunning = battles.some((battle) => {
    return getBattleStatus(battle) === BattleStatuses.ONGOING
  })

  const user = useUser()

  return (
    <AlertDialog.Root>
      <div
        className={`${styles.sidebarContainer} ${
          collapsible ? styles.sidebarContainerCollapsible : ''
        } ${visible ? styles.sidebarContainerVisible : ''}`}
      >
        <div className={styles.sidebarLogo}>
          <Link href={'/'}>
            <a className="logo-image  logo-image--full"></a>
          </Link>
        </div>
        <div className={styles.sidebarLogoSmall}>
          <Link href={'/'}>
            <a className="logo-image  logo-image--glyph"></a>
          </Link>
        </div>

        <div className={styles.sidebarMainContent}>
          <div className={styles.sidebarSection}>
            <SidebarPageItem icon="home" title="Home" link="/" />
          </div>

          {showIntro ? (
            <div className={styles.sidebarSection}>
              <div className={styles.sidebarSectionHeading}>
                <HStack>
                  <span style={{flex: '1'}}>Get Started</span>
                </HStack>
              </div>
              <OnboardingList />
            </div>
          ) : null}

          <div className={styles.sidebarSection}>
            <div className={styles.sidebarSectionHeading}>Play</div>

            <SidebarPageItem
              icon="daily-targets"
              title="Daily targets"
              link="/daily"
            />
            <SidebarPageItem
              icon="battle"
              title="Battles"
              link="/battles"
              tag={isBattleRunning ? 'ongoing' : null}
              matchRegex={/\/\/battle\/\S+/}
            />
            <SidebarPageItem
              icon="versus"
              title="Versus"
              link="/versus"
              tag="beta"
            />
            <SidebarPageItem
              icon="trophy"
              title="Leaderboards"
              link="/leaderboard"
              matchRegex={/\/leaderboard\/\S+/}
            />
            <SidebarPageItem
              icon="learn"
              title="Learn CSS"
              link="/learn"
              tag="new"
            />
          </div>
          {user && isMounted ? (
            <div className={styles.sidebarSection}>
              <div className={styles.sidebarSectionHeading}>For you</div>
              <SidebarPageItem
                icon="profile"
                title="Profile"
                link={`/player/${user.username || user.uid}`}
              />
              <SidebarPageItem icon="stats" title="Stats" link="/me" />
              <SidebarPageItem icon="gear" title="Settings" link="/settings" />
            </div>
          ) : null}
        </div>

        <div className={styles.sidebarFooter}>
          <VStack gap="1rem" align="stretch">
            {/* <div className={styles.sidebarSocialLinks}>
          <HStack gap="0.75rem">
            <SidebarSocialIcon icon="twitter" link="https://twitter.com" />
            <SidebarSocialIcon icon="discord-alt" link="https://twitter.com" />
            <SidebarSocialIcon icon="mail" link="https://twitter.com" />
          </HStack>
        </div> */}
            {isMounted ? (
              <GlowButton href="/plus" fullWidth>
                <HStack gap="0">
                  <span>{user?.isPro ? 'You are ' : 'Upgrade to '}</span>
                  <ProBadge />
                </HStack>
              </GlowButton>
            ) : null}
          </VStack>
        </div>
      </div>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="AlertDialogOverlay" />
        <AlertDialog.Content className="AlertDialogContent">
          <Panel>
            <VStack align="flex-start">
              <Text mono={false} size={3} weight={600} appearance="primary">
                Skip onboarding?
              </Text>

              <Text mono={false} size={1} weight={500} appearance="secondary">
                Are you sure you want to skip the onboarding? You won't be able
                to see it again.
              </Text>

              <HStack gap="0.75rem">
                <AlertDialog.Action asChild>
                  <Button
                    primary
                    onClick={() => {
                      setShowIntro(false)
                    }}
                  >
                    Yes, skip it!
                  </Button>
                </AlertDialog.Action>
                <AlertDialog.Cancel asChild>
                  <Button>Cancel</Button>
                </AlertDialog.Cancel>
              </HStack>
            </VStack>
          </Panel>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  )
}

export {CSSBattleSidebar}
