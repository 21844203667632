export const ProductEnum = {
  CUSTOM_BATTLE_MINI: 'customBattleMini',
  CUSTOM_BATTLE_SMALL: 'customBattleSmall',
  LEARN_BASIC: 'learnBasic',
  LEARN_INTERMEDIATE: 'learnIntermediate',
  CREDITS: 'credits',
}

export const Products = {
  [ProductEnum.CUSTOM_BATTLE_MINI]: {
    vendorId: '578130',
    label: 'Small',
    maxDuration: 1440, // in minutes
    price: '$199',
  },
  [ProductEnum.CUSTOM_BATTLE_SMALL]: {
    vendorId: '578132',
    label: 'Large',
    maxDuration: 10080, // in minutes
    price: '$399',
  },
  [ProductEnum.LEARN_BASIC]: {
    vendorId: '781594',
    dbId: 'basic',
    label: 'Basic',
    price: '$8.99',
  },
  [ProductEnum.LEARN_INTERMEDIATE]: {
    vendorId: '868760',
    dbId: 'intermediate',
    label: 'Intermediate',
    price: '$49.99',
  },
  [ProductEnum.CREDITS]: {
    vendorId: '895852',
    dbId: 'credits',
    label: 'Credits',
    price: '$3',
  },
}
