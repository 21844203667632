const VersusRoomStatuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive', // TODO: room created but battle never started, such rooms are marked as 'inactive' and deleted everyday with cron, if battle does not start for more than say 24 hrs, we can delete that room!
  FINISHED: 'finished',
}

const VersusGameModesEnum = {
  FIRST_TO_MATCH: 'firstToMatch',
  CODE_GOLF: 'codegolf',
  TAILWIND: 'tailwind',
  CODE_IN_THE_DARK: 'codeInTheDark',
  KNOCKOUT: 'knockout',
}

const VersusGameModes = {
  [VersusGameModesEnum.FIRST_TO_MATCH]: {
    label: 'Time Attack',
    emoji: '⏰',
    description: 'Fastest to get 100% match wins the game',
    isUpcoming: false,
  },
  [VersusGameModesEnum.CODE_GOLF]: {
    label: 'Code Golf',
    emoji: '⛳',
    description: 'Shortest code with a 100% match gets the highest score',
    isUpcoming: false,
  },
  [VersusGameModesEnum.TAILWIND]: {
    label: 'Tailwind only',
    isUpcoming: true,
    emoji: '',
    description: 'Code with only Tailwind CSS classes',
  },
  [VersusGameModesEnum.CODE_IN_THE_DARK]: {
    label: 'Code In the Dark',
    isUpcoming: true,
    emoji: '',
    description: 'Play without looking at the output',
  },
  [VersusGameModesEnum.KNOCKOUT]: {
    label: 'Knockout',
    isUpcoming: true,
    emoji: '',
    description: 'Last player in every match is out',
  },
}

const VersusTargetDifficultyEnum = {
  EASY: 'easy',
  MEDIUM: 'medium',
  HARD: 'hard',
}

const VersusTargetDifficulty = {
  [VersusTargetDifficultyEnum.EASY]: {
    label: 'Easy',
    isUpcoming: false,
  },
  [VersusTargetDifficultyEnum.MEDIUM]: {
    label: 'Medium',
    isUpcoming: true,
  },
  [VersusTargetDifficultyEnum.HARD]: {
    label: 'Hard',
    isUpcoming: true,
  },
}

const VersusBattleStatuses = {
  ONGOING: 'onGoing',
  FINISHED: 'finished',
}

module.exports = {
  VersusRoomStatuses,
  VersusGameModesEnum,
  VersusGameModes,
  VersusTargetDifficultyEnum,
  VersusTargetDifficulty,
  VersusBattleStatuses,
}
