import {createContext} from 'react'

export const UserContext = createContext({
  user: null,
  setUser: () => {},
})

export const GlobalContext = createContext({
  streak: null,
  battle: null,
  setBattle: null,
  level: null,
  setLevel: null,
  theme: 'dark',
  setTheme: () => {},
  room: null,
  setRoom: null,
})
